import axios from './axios'
// import { HttpResponse } from './common'

export function sendEmail(data?: any) {
  const { email } = data
  const url = '/golfapi/memuser/sendVerifCodeEmail?email=' + email
  return axios.get(url)
}
export function loginToAdmin(data?: any) {
  const url = '/golfapi/memuser/memLogin'
  return axios.post(url, data)
}
export function memRegister(data?: any) {
  const url = '/golfapi/memuser/memRegister'
  return axios.post(url, data)
}
export function renewPasswordByVerifCode(data?: any) {
  const url = '/golfapi/memuser/renewPasswordByVerifCode'
  return axios.post(url, data)
}
export function confirmRegister(data?: any) {
  const { memberIdStr } = data
  const url = '/golfapi/memuser/confirmRegister?memberIdStr=' + memberIdStr
  return axios.get(url, data)
}
export function getShirtSize() {
  const url = '/golfapi/code/getCodeListByType?codeType=MEMBER_SHIRT_SIZE'
  return axios.get(url)
}

export function imageUpload(data?: any) {
    const url = '/golfapi/picture/upload'
    return axios.post(url, data)
  }