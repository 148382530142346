import Axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
const baseApi = process.env.VUE_APP_BASE_URL
const service = Axios.create({
    baseURL: baseApi,
    timeout: 10000
})
service.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (config.url.includes("/golfapi/picture/upload")) {
        config['headers']['Content-Type'] = "multipart/form-data"
    }
    return config
})

service.interceptors.response.use(
    async (response: AxiosResponse) => {
        return Promise.resolve(response.data)
    },
    (error: AxiosError) => {
        let __emsg: string = error.message || ''

        if (error.message) {
            __emsg = error.message
        }

        if (error.response) {
            __emsg = error.response.data.message
                ? error.response.data.message
                : error.response.data.data
        }
        if (__emsg.indexOf('timeout') >= 0) {
            __emsg = 'timeout'
        }
        return Promise.reject(new Error(__emsg))
    }
)

export default service
